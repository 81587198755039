import React, { useState, useEffect } from 'react';
import styles from "../styles/home.module.css";
import bookmark from "../images/bookmark.svg"
import chat from "../images/chat.svg"
import home from "../images/home.svg"



const BottomBar = () => {
    return (
        <div className={styles.frameParent4}>
        <div className={styles.frameParent5}>
          <div className={styles.homeFill2Wrapper}>
            <img className={styles.frameChild} alt="" src={home}/>
          </div>
          <div className={styles.chat2Wrapper}>
            <img className={styles.frameChild} alt="" src={chat} />
          </div>
          <div className={styles.homeFill2Wrapper}>
            <img className={styles.frameChild} alt="" src={bookmark} />
          </div>
      
        </div>
        <div className={styles.systemFooter}>
          <div className={styles.homeIndicator} />
        </div>
      
      </div>
    )
}

export default BottomBar;
 