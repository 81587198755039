import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import styles from '../styles/home.module.css';
import dialogueStyles from '../styles/dialogue.module.css';
import volume from '../images/dialogue/speaker.svg';
import mute from '../images/dialogue/non-speaker.svg';
import storyIcon from '../images/logo-name.svg';
import userIcon from '../images/man.png';
import loadingGif from '../images/loader.webp';
import ResponseTable from '../components/ResponseTable';
import { startListening, stopListening } from '../components/startListening';
import SpeechRecognitionComponent from '../components/SpeechRecognitionComponent';
import ReadMore from '../components/ReadMore';
import SuggestedQuestions from '../components/SuggestedQuestions';
import ChartComponent from '../components/ChartComponent';
import arrowDown from '../images/dialogue/down-arrow.svg';

const Chat = () => {
  const [messages, setMessages] = useState([{ sender: 'bot', content: 'Hello, how can I help you?' }]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVolumeEnabled, setIsVolumeEnabled] = useState(true);
  const [latestResponse, setLatestResponse] = useState('');
  const [audioPath, setAudioPath] = useState('');
  const [suggestions, setSuggestions] = useState(['what is avg sales for each month in the year 2018', 'Which region contributed max sales in last 12 months', 'Give me top 3 products which was bought by the customers in year 2017']);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const chatEndRef = useRef(null);
  const audioRef = useRef(null);

  const fetchBotResponse = async (message) => {
    try {
      const company = "codewave";
      const chartType = "";
      const url = `https://api.storymetrics.ai/story/v1/chat/?question=${message}&graph_prompt=${chartType}&company_name=${company}`;
      const response = await axios.get(url, {});
      return response.data.data;
    } catch (error) {
      console.error('Error fetching bot response:', error);
      return { answer: 'Error: Unable to get response from bot.', suggestions: [] };
    }
  };

  const sendMessage = async (message) => {
    if (message.trim() === '') return;

    const userMessage = { sender: 'user', content: message };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setNewMessage('');
    setIsLoading(true);
    setShowSuggestions(false);

    const botResponse = await fetchBotResponse(message);
    const botMessage = {
      sender: 'bot',
      content: botResponse?.answer,
      graphType: botResponse?.graph_type,
      graph: botResponse?.table,
      table: botResponse?.table,
    };
    setMessages((prevMessages) => [...prevMessages, botMessage]);
    setLatestResponse(botResponse?.answer);
    setAudioPath(botResponse?.audio_path);
    setSuggestions(botResponse?.related_questions || []);
    setIsLoading(false);
    setShowSuggestions(true);

    if (isVolumeEnabled && botResponse?.audio_path) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      audioRef.current = new Audio(botResponse.audio_path);
      audioRef.current.play();
    }
  };

  const handleTranscript = (transcript) => {
    setNewMessage(transcript);
    setTimeout(() => {
      sendMessage(transcript);
      setNewMessage('');
    }, 1000);
  };

  const handleSuggestionClick = (suggestion) => {
    setNewMessage(suggestion);
    sendMessage(suggestion);
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isLoading]);

  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage(newMessage);
    }
  };

  const toggleVolume = () => {
    setIsVolumeEnabled((prev) => {
      const newVolumeState = !prev;
      if (audioRef.current) {
        if (newVolumeState) {
          audioRef.current.play();
        } else {
          audioRef.current.pause();
        }
      }
      return newVolumeState;
    });
  };

  const toggleSuggestions = () => {
    setShowSuggestions((prev) => !prev);
  };

  return (
    <div className={dialogueStyles.chatContainerWrapper}>
      <div className={dialogueStyles.chatWrapper}>
        {messages.map((message, index) => (
          <div key={index} className={message.sender === 'bot' ? styles.chatMessageBotContainer : styles.chatMessageUserContainer}>
            <div className={message.sender === 'bot' ? styles.chatMessageContainerDivBot : styles.chatMessageContainerDivUser}>
              <div className={message.sender === 'bot' ? styles.chatMessageContainerImageBot : styles.chatMessageContainerImageUser}>
                <img
                  src={message.sender === 'bot' ? storyIcon : userIcon}
                  alt={message.sender}
                  style={{ height: '30px', margin: '0px 10px 10px', alignItems: 'flex-start' }}
                />
              </div>
              <ReadMore text={message.content} maxLength={250} />
              {message?.graph && (
                <div style={{ position: 'relative', top: '10px', marginBottom: '10px', border: '1px solid #1a7472', borderRadius: '15px' }}>
                  <ChartComponent chartType={message.graphType} data={message.graph} />
                </div>
              )}
            </div>
          </div>
        ))}
        {isLoading && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <div className={styles.chatMessageContainerLoader} style={{}}>
              <img src={storyIcon} alt="bot" style={{ height: '30px', marginRight: '10px' }} />
              <img src={loadingGif} alt="loading" style={{ height: '20px' }} />
            </div>
          </div>
        )}
        <div ref={chatEndRef}></div>
      </div>
      {showSuggestions && <SuggestedQuestions suggestions={suggestions} onClickSuggestion={handleSuggestionClick} />}
      <div className={styles.chatMessageInputWrapper}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleEnterKeyPress}
          placeholder="Ask anything..."
          className={dialogueStyles.chatMessageInputWrapper}
        />
        <SpeechRecognitionComponent onTranscript={handleTranscript} />
        <img
          style={{ height: '25px', cursor: 'pointer', borderLeft: '1px solid grey', padding: '0px 10px' }}
          className={styles.homeFill2Icon}
          alt=""
          src={isVolumeEnabled ? volume : mute}
          onClick={toggleVolume}
        />
      </div>
    </div>
  );
};

export default Chat;