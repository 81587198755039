import styles from "../styles/dialogue.module.css";

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import GifLoader from 'react-gif-loader';

import axios from 'axios';


import edit from "../images/dialogue/edit.svg"
import arrow from "../images/dialogue/arrow_right.svg"
import arrowLeft from "../images/dialogue/arrow_left.svg"
import volume from "../images/dialogue/volume.svg"
import bookmark from "../images/bookmark.svg"
import chat from "../images/chat.svg"
import home from "../images/home.svg"
import loader from "../images/home/loader.svg"
import { Link } from "react-router-dom";
import BottomBar from "../components/bottom_bar";
import AskQuestion from "../components/askQuestion"
import Chat from "../components/Chat"

const ChatWindow = () => { 
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

 
  const navigateToResponse = (question) => {
    localStorage.setItem('Question',question)
    navigate(`/response?queryParam=${question}`);
  }

  useEffect(() => {
    // axios.get('https://api.storymetrics.ai/predefined-queries?dataset='+localStorage.getItem('selectedDS'))
    //   .then(response => {
    //     console.log("response",response)
    //     setData(response.data);
    //     setLoading(false);
    //   })
    //   .catch(err => {
    //     setError(err);
    //     setLoading(false);
    //   });
  }, []);

 
  return (
    <div className={styles.newDialog}>
      <div className={styles.startADialogParent}>
        {/* <b className={styles.startADialog}>Start A Dialogue</b> */}
        {/* <div className={styles.lastUpdate120226}>Last Update: 12.02.26</div> */}
      </div>
      {/* <div className={styles.systemStatusParent}> */}
      
        {/* <div className={styles.star242Parent}> */}
        {/* <Link to="/"><img className={styles.star242Icon} alt="" src={arrowLeft} /></Link> */}
        {/* </div> */}
      {/* </div> */}
    
    <Chat />

    {/* <BottomBar/> */}
     
    </div>
  );
};

export default ChatWindow;
