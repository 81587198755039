// import logo from './logo.svg';

import { Routes, Route } from 'react-router-dom';
import React, { Component } from 'react'; 
import { Helmet } from "react-helmet";


import "./App.css";
import "../src/styles/global.css";

import Home from './pages/home';
import Dialogue from './pages/dialogue';
import NewDialogue from './pages/new_dialogue';
import ChatWindow from './pages/chatWindow';
import VoiceQuery from './pages/voiceQuery';
import Response from './pages/response'
import NavBar from './components/NavBar';

function App() {
  return (
    
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Storymetrics</title>
          <link rel="canonical" href="www.codewave.com" />
        </Helmet>
        {/* <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header> */}
        {/* <NavBar/> */}
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/dialogue" element={<Dialogue />}></Route>
          <Route exact path="/new_dialogue" element={<NewDialogue />}></Route>
          <Route exact path="/chat" element={<ChatWindow />}></Route>
          <Route exact path="/voiceQuery" element={<VoiceQuery />}></Route>
          <Route exact path="/response" element={<Response />}></Route>

        </Routes>
       
      </div>
  );
}

export default App;
