// src/components/SpeechRecognitionComponent.js
import React, { useState } from 'react';
import microphoneIcon from '../images/dialogue/mute.svg';
import microphoneOffIcon from '../images/dialogue/unmute.svg';

const SpeechRecognitionComponent = ({ onTranscript }) => {
  const [isListening, setIsListening] = useState(false);
  const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();

  recognition.continuous = false;
  recognition.interimResults = false;
  recognition.lang = 'en-US';

  recognition.onstart = () => {
    setIsListening(true);
  };

  recognition.onend = () => {
    setIsListening(false);
  };

  recognition.onresult = (event) => {
    const transcript = event.results[0][0].transcript;
    onTranscript(transcript);
  };

  const toggleListening = () => {
    if (isListening) {
      recognition.stop();
    } else {
      recognition.start();
    }
  };

  return (
    <div>
      <img
        src={isListening ? microphoneOffIcon : microphoneIcon}
        alt="microphone"
        style={{ width: '30px', height: '25px', cursor: 'pointer', margin: '5px 0px 0px', borderLeft: '1px solid grey', paddingLeft: '10px' }}
        onClick={toggleListening}
      />
    </div>
  );
};

export default SpeechRecognitionComponent;