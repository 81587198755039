// src/ResponseTable.js
import React from 'react';

const ResponseTable = ({ data }) => {
  if (data?.length === 0) {
    return <p></p>;
  }

  if(data){
    const columns = Object.keys(data[0]);

    return (
      <div className='table-margin' style={{ padding: '20px', overflow: 'scroll' }}>
        <table  border="1" style={{ width: '100%', borderCollapse: 'collapse', overflow: 'scroll' }}>
          <thead>
            <tr className='App-center'>
              {columns.map((column) => (
                <th className='App-center' key={column}>{column}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column) => (
                  <td className='App-center' key={column}>{typeof row[column] === 'number' ? row[column].toFixed(2) : row[column]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  
};

export default ResponseTable;