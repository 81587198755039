let voices = null;
const getVoices = async () => {
  if (!voices) {
    voices = await new Promise((resolve) => {
      speechSynthesis.onvoiceschanged = () => {
        const availableVoices = speechSynthesis.getVoices();
        console.log('out side the voices', availableVoices);
        resolve(availableVoices);
      };
    });
  }
  return voices;
};
const getDefaultVoice = async () => {
  const defaultVoice = (await getVoices()).find(
    (v) => v.name === 'Google US English'
  );
  return defaultVoice || (await getVoices())[0];
};
export const startListening = async (text) => {
  console.log('hiii',text);
  const utterance = new SpeechSynthesisUtterance(text);
  utterance.voice = await getDefaultVoice();
  console.log(utterance.voice);
  utterance.rate = 0.8;
  utterance.text = text;
  window.speechSynthesis.cancel();
  window.speechSynthesis.speak(utterance);
};
export const stopListening = async (text) => {
  window.speechSynthesis.cancel();
};
export default {};