import React, { useEffect, useRef } from 'react';
import Plotly from 'plotly.js-basic-dist';

const ChartComponent = ({ chartType, data }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef.current && data && data.length > 0) {
      const xKey = Object.keys(data[0])[0]; // First key for x-axis
      const yKey = Object.keys(data[0])[1]; // Second key for y-axis

      const xValues = data.map(item => item[xKey]);
      const yValues = data.map(item => item[yKey]);

      let plotData = [];

      switch (chartType) {
        case 'bar':
          plotData = [{ x: xValues, y: yValues, type: 'bar', marker: { color: '#1a7472' } }];
          break;
        case 'line':
          plotData = [{ x: xValues, y: yValues, type: 'scatter', mode: 'lines', marker: { color: '#1a7472' } }];
          break;
        case 'pie':
          plotData = [{ labels: xValues, values: yValues, type: 'pie', marker: { colors: ['rgba(0, 0, 255, 1)', 'rgba(255, 0, 0, 1)', 'rgba(0, 255, 0, 1)', 'rgba(255, 255, 0, 1)', 'rgba(0, 255, 255, 1)', 'rgba(255, 0, 255, 1)', 'rgba(192, 192, 192, 1)', 'rgba(128, 128, 128, 1)', 'rgba(128, 0, 0, 1)', 'rgba(128, 128, 0, 1)', 'rgba(0, 128, 0, 1)', 'rgba(128, 0, 128, 1)', 'rgba(0, 128, 128, 1)', 'rgba(0, 0, 128, 1)'] } }];
          break;
        default:
          plotData = [{ x: xValues, y: yValues }];
      }

      const layout = {
        // title: `${chartType.charAt(0).toUpperCase() + chartType.slice(1)} Chart`,
        height: 400,
        xaxis: { title: xKey },
        yaxis: { title: yKey },
        plot_bgcolor: 'transparent', 
        paper_bgcolor: 'transparent', 
        font: {
          color: '#fff' // Set the text color
        },
        xaxis: {
            showgrid: false          // Hide x-axis grid lines
          },
          yaxis: {
            showgrid: false          // Hide y-axis grid lines
          }        
      };

      Plotly.newPlot(chartRef.current, plotData, layout);
    }
  }, [chartType, data]);

  return <div ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

export default ChartComponent;