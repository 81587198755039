import React, { useState, useEffect, useCallback } from 'react';
import PieChart from "../components/pieChart"
import BarGraph from "../components/barGraph"
import GifLoader from 'react-gif-loader';
import axios from 'axios';
import BottomBar from "../components/bottom_bar";
import { Link } from "react-router-dom";
import AskQuestion from "../components/askQuestion";
import {startListening} from '../components/startListening';
import {useSearchParams} from "react-router-dom";
import styles from "../styles/response.module.css";
import arrowLeft from "../images/dialogue/arrow_left.svg";
import volume from "../images/dialogue/volume.svg";
import man from "../images/man.png";
import loader from "../images/home/loader.svg"

import ResponseTable from '../components/ResponseTable';

const Response = () => {
// const { queryParam } = useParams();
const [loading, setLoading] = useState(true);
const [show, setshow] = useState(false);
const [responseData, setResponseData] = useState(null);
const [queryParam, setQueryParam] = useState('');

useEffect(() => {
const url = new URL(window.location.href); // Get the current URL
const queryParams = new URLSearchParams(url.search);
const paramValue = queryParams.get("queryParam");
console.log(paramValue);
if (paramValue) {
  setQueryParam(paramValue);
}
  askQuestion();
}, []);






// const askQuestion = async () => {
//    console.log("api called")
//    try {
//    const response = await axios.post(
//    'https://api.storymetrics.ai/ask-question',
//    {
//    question: localStorage.getItem('Question'),
//    dataset: localStorage.getItem('selectedDS'),
//    },
//    {
//    headers: {
//    'Accept': 'application/json',
//    'Content-Type': 'application/json',
//    },
//    }
//    );
//    // After receiving the response, update the state variables
//    setResponseData(response.data);
//    setshow(true)
//    setLoading(false); // Set loading to false
//    console.log('Response:', responseData);
//    } catch (error) {
//    // Handle errors here
//    console.error('Error:', error);
//    setLoading(false); // Set loading to false in case of an error
//    }
//    };
   

   

const askQuestion = async () => {
  console.log("api called");
  const question = localStorage.getItem('Question'); 
  // console.log(question);
  const url = 'https://api.storymetrics.ai/story/v1/chat/?question='+question;
  try {
    const response = await axios.get(url,{
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    }});
    console.log('Response:', response.data.data);
    // After receiving the response, update the state variables
    setResponseData(response.data.data);
    setshow(true)
    setLoading(false); // Set loading to false
    // console.log('Response:', responseData);
  } catch (error) {
  // Handle errors here
    console.error('Error:', error);
    setLoading(false); // Set loading to false in case of an error
  }};
  
return (
<div className={styles.response}>

   <div className={styles.star242Parent}>
    <div>
    <Link to="/new_dialogue">
      <img className={styles.homeFill2Icon} alt="" src={arrowLeft}/></Link>
    <b className={styles.revenue} style={{textTransform:"capitalize"}}>{localStorage.getItem('selectedDS')}</b>
    </div>
      
    <img
  className={styles.homeFill2Icon}
  alt=""
  src={volume}
  onClick={() => {
    if (responseData && responseData.answer !== null) {
      startListening(responseData.answer);
    } else {
      startListening("No data available");
    }
  }}
/>


   </div>
  <div className={styles.response_screen}>
  <div className={styles.f69cTractor1Parent1}>
   <img
      className={styles.f69cTractor1Icon}
      alt=""
      src={man}
      />
   <div className={styles.thatsATough}>{ localStorage.getItem('Question')}</div>
  </div>

  
   <div className={styles.f69cTractor1Group}>
      <div className={styles.thatsATough1}>
         <div>
            {loading ? (
            <div style={{ background: 'transparent' }}>
            <GifLoader
               loading={true}
               imageSrc={loader}
               overlayBackground="transparent" // Set overlayBackground to a transparent value
               />
         </div>
         ) : (
         // Render the response data when it's available
         <div>
           <div dangerouslySetInnerHTML={{ __html: responseData?.answer }} />
            <div style={{position:"relative",top:"25px"}}>
                {responseData?.graph && <img src={`data:image/png;base64,${responseData.graph}`} alt="Graph" />}
            </div>
            
            <div style={{position:"relative",top:"25px"}}>
              <ResponseTable data={responseData?.table} /> 
            </div>

            {responseData?.type_of_graph !== '' ? (
        <div>
          {/* {responseData.type_of_graph === "bar" ? <div style={{position:"relative",top:"25px"}}><BarGraph x={responseData?.graph1.x} y={responseData?.graph1.y}/></div> : null} */}
          {/* {responseData.type_of_graph === "pie" ? <div style={{position:"relative",top:"-25px"}}><PieChart x={responseData?.graph1.x} y={responseData?.graph1.y}/></div> : null} */}
        </div>
      ) : (
        null
      )}
           
          
           
         </div>
         )}
      </div>
   </div>
</div>
{/* {show && (
  <div className={styles.component7frame494}>
    <div className={styles.component7frame494Child} />
    <div className={styles.thatsATough}>
      Ask next: How did the trend change?
    </div>
    <AskQuestion />
  </div>
)} */}

    <BottomBar/>
  </div>

</div>
);
};
export default Response;