import styles from "../styles/home.module.css";
import React, { useState, useEffect } from 'react';
import GifLoader from 'react-gif-loader';
import { motion, AnimatePresence  } from "framer-motion";
import axios from 'axios';
import logo from "../images/home/logo.svg"
import que1 from "../images/Que1.svg"
import que2 from "../images/Que2.svg"

import pin_icon from "../images/home/pin_icon.png"
import loader from "../images/home/loader.svg"
import settings from "../images/home/settings.png"
import search from "../images/home/search.svg"
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

import BottomBar from "../components/bottom_bar";
const Dialogue = () => {
const [data, setData] = useState(null);
const [loading, setLoading] = useState(true);
const [showOptions, setShowOptions] = useState(false);
const [error, setError] = useState(null);
const [selectedDS, setSelectedDS] = useState('');

const fadeInOut = {
   initial: { opacity: 0 },
   animate: { opacity: 1 },
   exit: { opacity: 0 },
 };
const navigate = useNavigate();

const handleSelectChange = (event) => {
   const selectedValue = event.target.value;
   setSelectedDS(selectedValue); // Update the state with the selected value
   localStorage.setItem('selectedDS', selectedValue); // Store the selected value in localStorage
 };

const navigateToResponse = (question) => {
   localStorage.setItem('Question',question)
   navigate(`/response?queryParam=${question}`);
 }
const toggleOptions = () => {
setShowOptions(!showOptions);
};
useEffect(() => {
   // axios.get('https://api.storymetrics.ai/recent-queries?dataset='+localStorage.getItem('selectedDS')).then(response => {
   //    console.log("response",response)
   //    setData(response.data);
   //    setLoading(false);
   // }).catch(err => {
   //    setError(err);
   //    setLoading(false);
   // });
}, []);
return (
<div className={styles.home}>
   <div className={styles.systemStatusParent}>
      <div className={styles.systemStatus}>
      </div>
      <div className={styles.component7Parent}>
         <div className={styles.component7}>
            <div style={{width:"75%"}}>
               <b className={styles.history}>Dialogues</b>
               <img
                  className={styles.component7Child}
                  alt=""
                  src={logo} 
                  />
            </div>
            {/* <div className={styles.settings_icon}>
               <img src={settings} width="40px" height="40px" onClick={toggleOptions}/>
               {showOptions && (
               <div className={styles.browseAndHire}>
                  <select name="dataset" id="dataset" onChange={handleSelectChange} value={selectedDS}>
                  <option value="">select dataset</option>
                        <option value="zomato">Zomato</option>
                        <option value="datacat">Datacat</option>
                        
                     </select>
               </div>
               )}
            </div> */}
         </div>
         <div className={styles.component71}>
            <div className={styles.frameParent}>
               <Link to="/new_dialogue">
               <b className={styles.newChat}>+ New Dialogue</b></Link>
            </div>
            <div className={styles.component7Inner}>
              
            </div>
         </div>
      </div>
      <div className={styles.frameGroup}>
         <div className={styles.frameContainer}>
            <div className={styles.recentWrapper}>
               <div className={styles.recent}>RECENT</div>
            </div>
            {loading ? (
            // Display a loading indicator if loading is true
            <div style={{ background: 'transparent' }}>
            <GifLoader
               loading={true}
               imageSrc={loader}
               overlayBackground="transparent"
               />
         </div>
         ) : (
          data?.length === 0 ? (
            // Display a message when data is empty
            <div className={styles.frame472defaultParent}>No data available</div>
          ) : (
            // Render data if loading is false and data is not empty
            <AnimatePresence>
  {data?.slice(0, 2).map((item, index) => {
    const createdAt = new Date(item.created_at);
    const hours = createdAt.getHours().toString().padStart(2, '0');
    const minutes = createdAt.getMinutes().toString().padStart(2, '0');
    const time = `${hours}:${minutes}`;
    return (
      <div className={styles.frame472defaultParent} key={index}>
        <div className={styles.frame472default}>
          <img
            className={styles.tickCircle11defaultIcon}
            alt=""
            src="/tickcircle1-1default.svg" // Verify the image path
          />
          <div className={styles.f69cTractor1Parent}>
            <img
              className={styles.f69cTractor1Icon}
              alt=""
              src={que1} // Verify the image path
            />
            <div className={styles.frameDiv}>
              <div className={styles.browseAndHireTopFretElancParent}>
                <motion.div
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={fadeInOut}
                >
                  <div className={styles.browseAndHire} onClick={() => navigateToResponse(item.question)}>
                    {item.question}
                  </div>
                </motion.div>
                {/* <div className={styles.div}>{time}</div> */}
              </div>
              <div className={styles.whatsTheBest}>
                <p>{item.answer.slice(0, 37)}...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  })}
</AnimatePresence>

          )
          )}
      </div>
      <div className={styles.frameParent2}>
         <div className={styles.recentWrapper}>
            <div className={styles.recent}>PINNED</div>
         </div>
         <div className={styles.frame472defaultGroup}>
            <div className={styles.frame472default}>
               <img
                  className={styles.tickCircle11defaultIcon}
                  alt=""
                  src="/tickcircle1-1default2.svg"
                  />
               <div className={styles.f69cTractor1Parent}>
                  <img
                     className={styles.f69cTractor1Icon}
                     alt=""
                     src={pin_icon}
                     />
                  <div className={styles.frameDiv}>
                     <div className={styles.browseAndHireTopFretElancParent}>
                        <div className={styles.browseAndHire}>
                           Most bought items Sep 2023
                        </div>
                        {/* <div className={styles.div}>05:12</div> */}
                     </div>
                     <div className={styles.whatsTheBest}>
                        North Indian thali, Chola Batura, Poha
                     </div>
                  </div>
               </div>
            </div>
            <div className={styles.frameItem} />
            </div>
         </div>
      </div>
   </div>
   <BottomBar/>
   {/* 
   <div>
      <h1>Recent Queries</h1>
      {data && (
      <ul>
         {data.map((item, index) => (
         <li key={index}>
            <h3>{item.question}</h3>
            <p>{item.answer.split(' ').slice(0, 10).join(' ')}...</p>
            <p>Created At: {item.created_at}</p>
         </li>
         ))}
      </ul>
      )}
   </div>
   */}
   <div className={styles.component6}>
      <Link to="/new_dialogue">
      <b className={styles.newChat}>+ New Dialogue</b></Link>
   </div>
</div>
);
};
export default Dialogue;