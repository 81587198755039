import styles from "../styles/voiceQuery.module.css";

import volume from "../images/dialogue/volume.svg"
import close from "../images/dialogue/close.svg"

const VoiceQuery = () => {
  return (
    <div className={styles.voiceQuery}>
  
      <div className={styles.rectangleParent}>
        <div className={styles.groupChild} />
       
        {/* <img className={styles.siriIcon} alt="" src="/siri.svg" /> */}
        <b className={styles.whichRestaurantsGenerated}>
          Which restaurants generated the most revenue last 6 months?
        </b>
        <div className={styles.instanceParent}>
          <div className={styles.star242Parent}>
            <img className={styles.star242Icon} alt="" src={close} />
            <img className={styles.star242Icon} alt="" src={volume} />
          </div>
          <div className={styles.systemFooter}>
            <div className={styles.homeIndicator} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoiceQuery;
