// src/components/ReadMore.js
import React, { useState } from 'react';

const ReadMore = ({ text, maxLength = 200 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  if (text.length <= maxLength) {
    return <div style={{marginLeft: '10px'}}>{text}</div>;
  }

  return (
    <p>
      {isExpanded ? text : `${text.slice(0, maxLength)}...`}
      <span 
        onClick={toggleExpansion} 
        style={{ color: '#7357eb', cursor: 'pointer', marginLeft: '5px' }}
      >
        {isExpanded ? 'Read Less' : 'Read More'}
      </span>
    </p>
  );
};

export default ReadMore;