// src/components/SuggestedQuestions.js
import React from 'react';
import styles from '../styles/suggestedQuestions.module.css';

const SuggestedQuestions = ({ suggestions, onClickSuggestion }) => {
  return (
    <div className={styles.suggestedQuestionsContainer}>
      <div className={styles.suggestedQuestionsDiv} style={{display: 'flex', width: '60%' }}>
        {suggestions.map((suggestion, index) => (
            <div 
            key={index} 
            className={styles.suggestionBox} 
            onClick={() => onClickSuggestion(suggestion)}
            >
            {suggestion}
            </div>
      ))}
      </div>
    </div>
  );
};

export default SuggestedQuestions;
